/**
 * Created by Alex Shvets on 19.02.2017.
 */

import differenceBy from 'lodash/differenceBy';
import uniq from 'lodash/uniq';
import { MAIL_MODAL } from './formsActions';

export const PREPARE_DATA = {
  data() {
    return {
      usersUpdated: false,
    };
  },
  methods: {
    /**
     * Проверка на разность массивов прошлых и текущих запрашиваемыхх данных
     * и дальнейший запрос привилегий в зависимости от проверки: либо полный массив, либо его часть
     *
     * @param v {array} текущий список
     * @param old {array} прошлый список
     */
    extendedCheck(v, old) {
      if (window.localStorage['access-token']) {
        if (old.length && !this.usersUpdated && v.length) {
          const diff = differenceBy(v, old, 'user_id');
          if (diff.length) {
            this.massCheckPrivileges(diff).then((r) => {
              r.forEach((i) => {
                const result = v.find((j) => i.user_id === j.user_id);
                Object.assign(result, i);
              });
              // this.$forceUpdate();
              // this.usersUpdated = false;
            });
          }
        } else if (v.length && !this.usersUpdated) {
          this.massCheckPrivileges(v).then(() => {
            // this.$forceUpdate()
          });
          // this.usersUpdated = false;
        }
      }
    },
    /**
     * Очистить состояние для возможности повторного обновление првилегий
     */
    clearUpdate() {
      this.usersUpdated = false;
    },
    /**
     * Проверить привилегии для одного пользователя
     *
     * @param target {object} с пользователем
     * @param field {string} поле, в котором хранится ИД
     * @return {Promise}
     */
    singleArrayCheckPrivileges(target, field) {
      return new Promise((resolve) => {
        if (window.localStorage['access-token']) {
          this.usersUpdated = true;
          const arr = [target[field]];
          this.$http
            .post('v1/favorite/is-favorite-array', {
              access_token: window.localStorage['access-token'],
              target_user_array: JSON.stringify(arr),
            })
            .then((response) => {
              if (response.body.status) {
                const record = response.body.result[0];
                const disabled = {
                  bookmarks: record.bookmark,
                };
                switch (record.favorite) {
                  case 'in favorite':
                    Object.assign(disabled, {
                      favs: {
                        status: true,
                        isFav: true,
                      },
                    });
                    break;
                  case 'can add':
                  case 'ignore':
                  case 'invite me':
                    Object.assign(disabled, {
                      favs: {
                        status: false,
                        isFav: false,
                      },
                    });
                    break;
                  case 'invite':
                  case 'ignore me':
                    Object.assign(disabled, {
                      favs: {
                        ignored: true,
                      },
                    });
                    break;
                }
                target = { disabled, ...target };
                resolve(target);
              }
            });
        }
      });
    },
    /**
     * Проверка привилегий для массива пользователей
     *
     * @param targetArr {array}
     * @return {Promise}
     */
    massCheckPrivileges(targetArr) {
      return new Promise((resolve) => {
        if (window.localStorage['access-token']) {
          const collection = targetArr.map((i) => i.user_id).filter((i) => !!i);
          this.$http
            .post('v1/favorite/is-favorite-array', {
              access_token: window.localStorage['access-token'],
              target_user_array: JSON.stringify(uniq(collection)),
            })
            .then((response) => {
              if (response.body.status) {
                targetArr.forEach((i) => {
                  const record = response.body.result.find((j) => i.user_id === j.user_id);

                  if (record) {
                    const disabled = {
                      bookmarks: record.bookmark,
                    };
                    switch (record.favorite) {
                      case 'in favorite':
                        Object.assign(disabled, {
                          favs: {
                            status: true,
                            isFav: true,
                          },
                        });
                        break;
                      case 'can add':
                      case 'ignore':
                      case 'invite me':
                        Object.assign(disabled, {
                          favs: {
                            status: false,
                            isFav: false,
                          },
                        });
                        break;
                      case 'invite':
                      case 'ignore me':
                        Object.assign(disabled, {
                          favs: {
                            ignored: true,
                          },
                        });
                        break;
                    }

                    // let obj = Object.assign( {},  i,  {disabled} );
                    this.$set(i, 'disabled', disabled);

                    // this.$set( targetArr, index, obj )
                  }
                }, this);
                resolve(targetArr);
              }
            });
        }
      });
    },
  },
};

export const COMPARE_DATA = {
  computed: {
    computedDisabled() {
      return this.disabledProps ? this.disabledProps : {};
    },
  },
  watch: {
    /**
     * Обновить состояние отключенных привилегий в соответствии с
     * состоянием, пришедшее из родителя
     * @param v {object}
     */
    computedDisabled(v) {
      this.disabled = { ...this.disabled, ...v };
    },
  },
  mounted() {
    if (this.disabledProps) {
      this.disabled = { ...this.disabled, ...this.disabledProps };
    }
  },
};

export const ACTIONS_DATA = {
  data() {
    return {
      disabled: {
        wink: false,
        chat: false,
        mail: false,
        gift: false,
        bookmarks: false,
        favs: {
          status: false,
          isFav: false,
          ignored: false,
          tmp: false,
        },
      },
    };
  },
  watch: {
    /**
     * Обновить состояние привилегий в компоненте-пользователе, для котороо уже в другом компоненте,
     * но этого же пользователя, было совершено некое действие: винк, фаворит и пр
     *
     * @param v {object}
     */
    lastAction(v) {
      if (v.id === this.user.user_id) {
        this.disabled = { ...this.disabled, ...v.disabled };
      }
    },
  },
  computed: {
    lastAction() {
      return this.$store.getters.lastAction;
    },
  },
};

export const USER_ACTIONS = {
  computed: {
    config() {
      return this.$store.getters.config;
    },
    childWindow() {
      return this.$store.getters.childWindow;
    },
  },
  methods: {
    /**
     * Старт чата
     *
     * @param callback {*} проверка условия
     * @param id {number} ид пользователя
     * @param chatType {string} тип чата
     * @param room {room} ИД комнаты
     */
    previewStartChat(tabchat, callback, id) {
      if (callback && !this.disabled.chat) {
        /* let win;
				if (this.childWindow.name) {
					if (id) {
						const data = {
							action: 'new_chat',
							params: {
								user_id: id,
								type: chatType || 'textInvite', //textInvite, videoInvite,
								room
							}
						};

						this.childWindow.postMessage(JSON.stringify(data), this.config.chatURL)
						this.childWindow.focus();
					}
				} else {
					if ( id ) {
						win = window.open( `${this.config.chatURL}?id=${id}&token=${window.localStorage[ 'access-token' ]}`, 'Chat', `toolbar=no,
					menubar=no, width=${w}, height=${h}, resizable=yes, status=no` )
					} else {
						win = window.open( `${this.config.chatURL}?token=${window.localStorage[ 'access-token' ]}`, 'Chat', `toolbar=no,
					menubar=no, width=${w}, height=${h}, resizable=yes, status=no` )
					}

					this.$store.commit('setChildWindow', win)
				} */

        // TODO: TEMPORARY

        if (tabchat) {
          const windowReference = window.open();
          windowReference.location = `${this.config.chatURL}?token=${window.localStorage['access-token']}`;
        } else {
          const w = window.innerWidth;
          const h = window.innerHeight - 60;

          /* setTimeout(() => {
            this.$store.commit('addAlert', {
              type: 'info',
              text: 'Please note, only one opened chat tab is allowed.',
              info: 'change pass',
            })
          }, 1000) */
          if (id) {
            window.open(
              `${this.config.chatURL}?id=${id}&token=${window.localStorage['access-token']}`,
              'Chat',
              `toolbar=no,
            menubar=yes, width=${w}, height=${h}, resizable=yes, status=no`
            );
          } else {
            window.open(
              `${this.config.chatURL}?token=${window.localStorage['access-token']}`,
              'Chat',
              `toolbar=no,
            menubar=yes, width=${w}, height=${h}, resizable=yes, status=no`
            );
          }
        }
        /* if (id) {
          window.location(
            `${this.config.chatURL}?id=${id}&token=${window.localStorage['access-token']}`,
            'Chat',
            `toolbar=no,
					menubar=no, width=${w}, height=${h}, resizable=yes, status=no`
          )
        } else {
          window.location(
            `${this.config.chatURL}?token=${window.localStorage['access-token']}`,
            'Chat',
            `toolbar=no,
					menubar=no, width=${w}, height=${h}, resizable=yes, status=no`
          )
        } */
      }
    },
    /**
     * открыть модалку с отправкой письма
     *
     * @param callback {*} условие
     * @param user {object} объект с пользователем
     */
    previewSendMail(callback, user) {
      if (callback) {
        MAIL_MODAL.methods.toggleMailModal(user, this);
      }
    },
    /**
     * открыть модалку с отправкой подарка
     *
     * @param callback {*} условие
     * @param id {number} ид клиента
     */
    previewSendGift(callback, id, retryGift) {
      if (callback) {
        this.$store.dispatch('updateGiftModal', {
          user_id: id,
          open: true,
          retryGift: retryGift || null,
        });
      }
    },
    previewSendVirtualGift(callback, id) {
      if (callback) {
        this.$store.dispatch('updateVirtualGiftModal', {
          user_id: id,
          open: true,
        });
      }
    },
    /**
     * совершить действие по добавлению/удаления из закладок
     *
     * @param callback {*} условие
     * @param target {number} ид пользователя
     */
    previewAddBookmark(callback, target) {
      if (callback) {
        if (this.disabled.bookmarks) {
          this.bookmarkRemove(target);
        } else {
          this.bookmarkAdd(target);
        }
      }
    },
    /**
     * совершить действие по добавление/удаление фаворита
     *
     * @param callback {*} условие
     * @param target {number} ид пользователя
     */
    previewAddToFavs(callback, target) {
      if (callback) {
        // this.disabled.favs.status = true;
        // this.disabled.favs.isFav = true;

        if (this.disabled.favs.ignored) return;

        if (this.disabled.favs.isFav || this.disabled.favs.status) {
          this.deleteFav(target);
          // } else if ( this.disabled.favs.status ) { //if follower
          // 	this.unfollowFav( target )
        } else {
          this.favoriteAdd(target);
        }
      }
    },
    /**
     * добавить пользователя в фавориты
     *
     * @param target {number} ид пользовалетя
     */
    favoriteAdd(target) {
      this.$http
        .post('v1/favorite/add', {
          access_token: window.localStorage['access-token'],
          favorite_user_id: target,
        })
        .then(() => {
          // this.currentSingleCheckPrivileges( true, target )
          this.canAddFav(target);
          /* this.canAddFav( target );
				 this.$store.commit( 'setAction', {
				 id: target,
				 disabled: {
				 favs: {
				 isFav: true
				 }
				 }
				 } ) */
        });
    },
    /**
     * подмигнуть пользователю
     *
     * @param callback {*} условие
     * @param targetId {number} ид пользователя
     */
    previewSendWink(callback, targetId) {
      if (callback) {
        if (!this.disabled.wink) {
          this.$http.post('v1/wink/make', {
            access_token: window.localStorage['access-token'],
            target_user_id: targetId,
          });
        }
        this.disabled.wink = true;
      }
    },
    /**
     * удаление пользователя из фаворитов
     *
     * @param target {number} ид пользователя
     */
    deleteFav(target) {
      this.$http
        .post('v1/favorite/delete', {
          access_token: window.localStorage['access-token'],
          favorite_user_id: target,
        })
        .then((response) => {
          if (response.body.status) {
            this.disabled.favs.status = false;
            this.disabled.favs.isFav = false;
            // this.currentSingleCheckPrivileges( true, target );
            this.canAddFav(target);
          }
        });
    },
    /**
     * добавить пользователя в закладки
     *
     * @param target {number} ид ползователя
     */
    bookmarkAdd(target) {
      this.$http
        .post('v1/bookmark/add', {
          access_token: window.localStorage['access-token'],
          bookmark_user_id: target,
        })
        .then(() => {
          this.canAddBookmark(target);
        });
    },
    /**
     * убрать из закладок
     *
     * @param target {number} ид пользователя
     */
    bookmarkRemove(target) {
      this.disabled.bookmarks = false;
      this.$http
        .post('v1/bookmark/delete', {
          access_token: window.localStorage['access-token'],
          bookmark_user_id: target,
        })
        .then(() => {
          this.canAddBookmark(target);
        });
    },
  },
};

export const CHECK_PRIVILEGES = {
  methods: {
    /**
     * Проверка привилегий взаимодействия с пользователем
     *
     * @param condition {*} условие
     * @param target {number} ид пользователя
     */
    currentSingleCheckPrivileges(condition, target) {
      // use in components, not its parents
      if (condition) {
        const arr = [target];
        this.$http
          .post('v1/favorite/is-favorite-array', {
            access_token: window.localStorage['access-token'],
            target_user_array: JSON.stringify(arr),
          })
          .then((response) => {
            if (response.body.status) {
              const record = response.body.result[0];
              const disabled = {
                bookmarks: record.bookmark,
              };

              switch (record.favorite) {
                case 'in favorite':
                  Object.assign(disabled, {
                    favs: {
                      status: true,
                      isFav: true,
                      tmp: false,
                    },
                  });
                  break;
                case 'can add':
                case 'ignore':
                case 'invite me':
                  Object.assign(disabled, {
                    favs: {
                      status: false,
                      isFav: false,
                      tmp: false,
                    },
                  });
                  break;
                case 'invite':
                case 'ignore me':
                  // eslint-disable-next-line no-case-declarations
                  const obj = {
                    favs: {
                      ignored: true,
                      tmp: false,
                    },
                  };
                  if (this.sex.isWoman && record.favorite === 'invite') {
                    obj.favs.tmp = true;
                  }
                  Object.assign(disabled, obj);
                  break;
              }
              this.$store.commit('setAction', {
                id: target,
                disabled,
              });
              // this.disabled = Object.assign( {}, this.disabled, disabled );
            }
          });
      }
    },

    /**
     * можно ли добавить пользователя в закладки
     *
     * @param target {number} ид пользователя
     */
    canAddBookmark(target) {
      if (window.localStorage['access-token']) {
        this.$http
          .post('v1/bookmark/can', {
            access_token: window.localStorage['access-token'],
            target_user_id: target,
          })
          .then((response) => {
            // this.disabled.bookmarks = !response.body;
            this.$store.commit('setAction', {
              id: target,
              disabled: {
                bookmarks: !response.body,
              },
            });
          });
      }
    },
    /**
     * можно ли подмигнуть пользователю
     *
     * @param target {number} ид пользователя
     */
    canWink(target) {
      if (window.localStorage['access-token']) {
        this.$http
          .post('v1/wink/can', {
            access_token: window.localStorage['access-token'],
            target_user_id: target,
          })
          .then((response) => (this.disabled.wink = !response.body.result));
      }
    },
    /* canAddFav( target ) {
		 if ( window.localStorage[ 'access-token' ] ) {
		 this.$http.post( 'v1/favorite/can', {
		 access_token: window.localStorage[ 'access-token' ],
		 favorite_user_id: target
		 } ).then( response => {
		 // this.disabled.favs.status = !response.body;
		 if (!response.body && !this.disabled.favs.isFav) {
		 this.$store.commit( 'setAction', {
		 id: target,
		 disabled: {
		 favs: {
		 ignored: true
		 }
		 }
		 } )
		 return;
		 }
		 this.$store.commit( 'setAction', {
		 id: target,
		 disabled: {
		 favs: {
		 status: !response.body
		 }
		 }
		 } )

		 } )
		 }
		 }, */
    /**
     * можно ли добавить пользователя в фавориты
     *
     * @param target {number} ид пользователя
     */
    canAddFav(target) {
      if (window.localStorage['access-token']) {
        this.$http
          .post('v1/favorite/is-favorite', {
            access_token: window.localStorage['access-token'],
            favorite_user_id: target,
          })
          .then((response) => {
            if (response.body) {
              // this.disabled.favs.isFav = true;
              this.$store.commit('setAction', {
                id: target,
                disabled: {
                  favs: {
                    isFav: true,
                  },
                },
              });
            } else {
              // this.disabled.favs.isFav = false;
              this.$store.commit('setAction', {
                id: target,
                disabled: {
                  favs: {
                    isFav: true,
                  },
                },
              });
            }
          });
      }
    },
    /**
     * можно ли пригласить пользователя в чат
     *
     * @param online {number|boolean}
     */
    canChat(online) {
      console.log('canChat')
      if (online || !window.localStorage['access-token']) {
        this.disabled.chat = false;
      } else {
        this.disabled.chat = true;
      }
    },
    /**
     * спросить привилегии по взаимодействию с пользователем
     *
     * @param id {number} ид пользователя
     * @param online {number|boolean}
     */
    askPrivileges(id, online) {
      this.canChat(online);
      this.canAddFav(id);
      this.canAddBookmark(id);
    },
  },
};

export const LOGOUT = {
  methods: {
    logout() {
      this.$http
        .post('v1/user/logout', {
          access_token: window.localStorage['access-token'],
        })
        .then(
          () => {
            this.$store.dispatch('logout');
          },
          () => {
            this.$store.dispatch('logout');
          }
        );
    },
  },
};
